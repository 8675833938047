<app-header></app-header>
<ng-template ngFor let-config [ngForOf]="snowFlakes">

	<snow-flake
		[depth]="config.depth"
		[speed]="config.speed"
		[style.left.vw]="config.left"
	></snow-flake>

</ng-template>
<router-outlet></router-outlet>
  