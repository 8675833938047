<div *ngIf="showMaintainerList" style="background-color: #ffe9e9; padding-bottom: 2.5rem;">

  <div class="container cl_animation1" style="text-align: center;">
    <img src="./assets/images/team-banner.webp" class="card-img" style="width: 20rem; mix-blend-mode: darken;"
      alt="avatar">
  </div>

  <p class="team-head cl_animation1">Core Members</p>
  <p class="teamtitle-sum cl_animation1">These are the people who play an important
    role in maintaining Project Elixir source from day to day work to monthly updates</p>

  <p class="cl_animation1 team-quote">
    "Coming together is a beginning, staying together is progress, and working together is success." <strong
      class="muted_text">- Henry Ford</strong>
  </p>

</div>


<div class="container">

  <!-- Core members -->
  <div class="container website_animation" style="padding-top: 1.8rem;">

    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1067316301"
      style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
    </ng2-adsense>

    <div class="row justify-content-center ">
      <div *ngFor="let team of teamList" class="card mb-3 card-radius rounded">
        <div class="row no-gutters shadow-light p-3 bg-body rounded">

          <div class="col-md-4 mb-align">
            <img src="{{team.avatar}}" class="card-img avatar img-fluid" alt="avatar">
          </div>

          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title coreteam">{{team.name}} <i class="material-icons">verified</i></h5>
              <p class="card-text muted_text" style="font-size: 0.9rem; font-weight: 500;">{{team.role}}</p>
              <a target="_blank" href="{{team.github}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="#cf7b7d" class="bi bi-github"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                </svg>
              </a>
              <a target="_blank" href="{{team.twitter}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi bi-twitter marLR1 "
                  viewBox="7.025 7.025 497.951 497.95">
                  <path fill="#cf7b7d"
                    d="M256 7.025C118.494 7.025 7.025 118.494 7.025 256S118.494 504.975 256 504.975 504.976 393.506 504.976 256C504.975 118.494 393.504 7.025 256 7.025zm-66.427 369.343h-54.665V199.761h54.665v176.607zM161.98 176.633c-17.853 0-32.326-14.591-32.326-32.587 0-17.998 14.475-32.588 32.326-32.588s32.324 14.59 32.324 32.588c.001 17.997-14.472 32.587-32.324 32.587zm232.45 199.735h-54.4v-92.704c0-25.426-9.658-39.619-29.763-39.619-21.881 0-33.312 14.782-33.312 39.619v92.704h-52.43V199.761h52.43v23.786s15.771-29.173 53.219-29.173c37.449 0 64.257 22.866 64.257 70.169l-.001 111.825z" />
                </svg>
              </a>
              <a target="_blank" href="{{team.telegram}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="#cf7b7d" class="bi bi-telegram "
                  viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                </svg>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

  <!-- Ex Source Maintainers -->
  <!-- <div *ngIf="showMaintainerList">
    <h3 class="text-center team-head">Ex Developer / Team Members</h3>
  </div>

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1067316301"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <div *ngIf="showMaintainerList" class="row justify-content-center ">
    <div *ngFor="let exteam of exteamList" class="card mb-3 card-radius rounded">
      <div class="row no-gutters shadow-light p-3 bg-body rounded">

        <div class="col-md-4 mb-align">
          <img src="{{exteam.avatar}}" class="card-img avatar img-fluid" alt="avatar">
        </div>

        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title excoreteam">{{exteam.name}}</h5>
            <p class="card-text muted_text" style="font-size: 0.85rem;">{{exteam.role}}</p>
            <a target="_blank" href="{{exteam.github}}">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="#cf7b7d" class="bi bi-github"
                viewBox="0 0 16 16">
                <path
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
              </svg>
            </a>
            <a target="_blank" href="{{exteam.twitter}}">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi bi-twitter marLR1 "
                viewBox="7.025 7.025 497.951 497.95">
                <path fill="#cf7b7d"
                  d="M256 7.025C118.494 7.025 7.025 118.494 7.025 256S118.494 504.975 256 504.975 504.976 393.506 504.976 256C504.975 118.494 393.504 7.025 256 7.025zm-66.427 369.343h-54.665V199.761h54.665v176.607zM161.98 176.633c-17.853 0-32.326-14.591-32.326-32.587 0-17.998 14.475-32.588 32.326-32.588s32.324 14.59 32.324 32.588c.001 17.997-14.472 32.587-32.324 32.587zm232.45 199.735h-54.4v-92.704c0-25.426-9.658-39.619-29.763-39.619-21.881 0-33.312 14.782-33.312 39.619v92.704h-52.43V199.761h52.43v23.786s15.771-29.173 53.219-29.173c37.449 0 64.257 22.866 64.257 70.169l-.001 111.825z" />
              </svg>
            </a>
            <a target="_blank" href="{{exteam.telegram}}">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="#cf7b7d" class="bi bi-telegram "
                viewBox="0 0 16 16">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
              </svg>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div> -->

  <!-- Maintainers List -->

  <h3 *ngIf="showMaintainerList" class="text-center maintainer-head">Maintainers</h3>

  <p *ngIf="showMaintainerList" style="font-weight: 500; font-size: 1.15rem;">Our maintainers are the
    people who maintain Project
    Elixir for your device for monthly updates</p>

  <div class="container">
    <!-- <h3 *ngIf="showMaintainerList" class="text-center maintainer-head">Our Maintainers</h3>

    <p *ngIf="showMaintainerList" style="font-weight: 500; font-size: 1.15rem;">These are the
      people who maintain Project
      Elixir for your device for monthly updates</p> -->


    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1067316301"
      style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
    </ng2-adsense>

    <!-- <div *ngIf="showMaintainerList" class="row justify-content-center padB3 ">
      <div *ngFor="let m of mainatinerList" class="card mb-3 card-radius rounded">
        <div class="row no-gutters p-3 bg-body shadow-light rounded ">
          <div class="col-md-4 mb-align">
            <img src="{{m.avatar}}" class="card-img mainatiner-avatar" alt="m_avatar">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title ">{{m.maintainer}} <i class="material-icons">verified</i></h5>
              <p class="card-text muted_text" style="font-size: 0.85rem;"> Maintainer from {{m.country}}</p>

              <a target="_blank" href="{{m.github}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="#cf7b7d" class="bi bi-github marR1"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                </svg>
              </a>

              <a target="_blank" href="https://www.paypal.me/uglykid24"> <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29"
                  fill="#cf7b7d" class="bi bi-paypal marR1" viewBox="0 0 16 16">
                  <path
                    d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z" />
                </svg> </a>

              <a target="_blank" href="{{m.telegram}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="#cf7b7d" class="bi bi-telegram "
                  viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                </svg>
              </a>

            </div>
          </div>

        </div>
      </div>
    </div> -->
  </div>

<br>
<br>

  <!-- Scroll To Top -->
  <div *ngIf="showMaintainerList" class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button type="button" class="btn active scroll-btn" data-toggle="button" aria-pressed="true"
      (click)="scrollToTop()">
      <i class="fas fa-chevron-up"></i>
    </button>
  </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1067316301"
  style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<!-- Footer Support Card -->
<div *ngIf="showMaintainerList" class=" mb-5 bg-body " style="background-color: #ffe9e9; padding: 2rem;">
  <div class="row justify-content-center">
    <div class="">
      <div class="row no-gutters">

        <div class="col-md-6">
          <div class="">
            <h1 class="support_line"> Wanna Support Us ? </h1>
            <p class="patreon_highlight">Help to keep Project Elixir active and running by donating. Any
              amount is appreciated.<strong>シ </strong>
            </p>
            <h1> <a href="https://projectelixiros.com/donate">
                <a class="btn shadow footer-support-btn">Donate Now</a>
              </a></h1>

          </div>
        </div>

        <div class="col-md-6 mb-align" style="text-align: center;">
          <img src="./assets/images/donate-phone-hand.webp" class="card-img img-fluid down-carpet-img"
            style="width: 15rem;" alt="avatar">
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="showMaintainerList">
  <app-footer></app-footer>
</div>