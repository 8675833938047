<div *ngIf="showFooter" style="background-color: #ffe9e9;" class="container-fliud ">

  <br>

  <div class="container website_animation">

    <h1 class="donatepage_head"> Support the minds for their <strong class="key-high">Hardwork</strong></h1>

    <br>

    <p class="card-text" style="text-align: center;">In order to maintain the source, we need High-End Servers that are
      generally
      <strong class="key-high">very
        expensive and need to be
        renewed every single month.</strong> For users to download the ROM at high speed and keeping the backup
      file as
      well
      we need good quality cloud storage. To meet with our technical expenses, a cup of coffee, and the efforts
      of
      the developers we need your
      monetary support!
    </p>
  </div>

</div>

<div class="container-fliud">

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

<div class="container website_animation" style="padding-top: 3rem;">
  <p class="card-title ques2 text-muted" style="font-size: 4rem;">Giving <strong class="key-high">little</strong>, can <strong
    class="key-high">help</strong> a lot <svg class="mx-1 mt-1 heart" width="22" height="22"
    viewBox="0 0 22 20" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M21.4355 4.2008C21.0952 3.41277 20.6045 2.69866 19.9908 2.09846C19.3767 1.49646 18.6526 1.01806 17.858 0.689276C17.034 0.346993 16.1503 0.171792 15.258 0.173846C14.0063 0.173846 12.785 0.516619 11.7236 1.16408C11.4697 1.31896 11.2285 1.48908 11 1.67443C10.7715 1.48908 10.5303 1.31896 10.2764 1.16408C9.21504 0.516619 7.99375 0.173846 6.74199 0.173846C5.84062 0.173846 4.96719 0.346502 4.14199 0.689276C3.34473 1.01935 2.62617 1.49416 2.00918 2.09846C1.39472 2.69798 0.90387 3.41226 0.564453 4.2008C0.211523 5.02092 0.03125 5.89182 0.03125 6.7881C0.03125 7.63361 0.203906 8.51467 0.54668 9.41096C0.833594 10.16 1.24492 10.9369 1.77051 11.7215C2.60332 12.9631 3.74844 14.258 5.17031 15.5707C7.52656 17.7467 9.85996 19.2498 9.95898 19.3108L10.5607 19.6967C10.8273 19.8668 11.1701 19.8668 11.4367 19.6967L12.0385 19.3108C12.1375 19.2473 14.4684 17.7467 16.8271 15.5707C18.249 14.258 19.3941 12.9631 20.227 11.7215C20.7525 10.9369 21.1664 10.16 21.4508 9.41096C21.7936 8.51467 21.9662 7.63361 21.9662 6.7881C21.9688 5.89182 21.7885 5.02092 21.4355 4.2008V4.2008Z"
      fill="#FF0000"></path>
  </svg> </p>
</div>
  
  <!-- Donate Main Card -->
  <div class="container donate-card shadow-light website_animation">
    <div class="card card-radius" style="border: none;">
      <div class="row no-gutters">

        <div class="col-md-6">
          <div class="card-body">


            <br>
            <p class="card-text ">• As a matter of fact that in order to build a Custom ROM there are certain
              pre-requisite
              such as a <strong class="key-high">High-End Building Server (Jenkins)</strong> or PC having very High-End
              technical
              specification combined
              with a very High-Speed internet connection.</p>
            <br>
            <br>
            <p class="card-text ">• We developers need to <strong class="key-high">allot our time and hard work</strong>
              from our
              Academic's hefty
              schedule in the proper
              maintenance of the custom ROM so that we can provide users with timely and stable updates.</p>
            <br>
            <br>
            <p class="card-text ">• In order to maintain the source, we need High-End Servers that are generally
              <strong class="key-high">very
                expensive and need to be
                renewed every single month.</strong> For users to download the ROM at high speed and keeping the backup
              file as
              well
              we need good quality cloud storage. To meet with our technical expenses, a cup of coffee, and the efforts
              of
              the developers we need your
              monetary support!
            </p>
            <br>
            <br>
            <p class="card-text ">• We have supported over <strong class="key-high">50-60 different android devices and
                kept them
                alive</strong> with monthly
              security patches. Our
              goal is to reach 120$ every month for servers and maintainance. With this amount we will cover our server
              costs, get new hardware and
              expand ourselves to more devices. Also bring faster and stable updates for your devices.</p>

          </div>
        </div>

        <div class="col-md-6 mb-align donate-link-card">

          <div class="donate_links">
            <a target="_blank" href="https://www.paypal.me/uglykid24"> <img src="./assets/images/paypal.webp"
                class="img-fluid paypal" alt="paypal"> </a>
          </div>
          <p class="note-text">• NOTE : PayPal deducts transaction fee so we <strong>recommend you
              Buymeacoffee.</strong></p>

          <div class="bmc">
            <a target="_blank" href="https://www.buymeacoffee.com/Uglykid"><img
                src="https://img.buymeacoffee.com/button-api/?text=Buy me a beer&emoji=🍺&slug=Uglykid&button_colour=FFDD00&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=ffffff" /></a>
          </div>
          <!-- <div class="bmc">
            <a href="https://www.buymeacoffee.com/Uglykid"> <img src="./assets/images/bmc-button.webp" class="bmc"
                alt="buymeacoffee"> </a>
          </div> -->
          <p class="note-text">• NOTE : BMC membership starts at as <strong>low as 2$</strong> and it has custom donate
            option too with a very <strong>minimal tax</strong> cuts.</p>

          <div class="donate_links">
            <a target="_blank" href="https://www.patreon.com/join/uglykid24"> <img
                src="./assets/images/patreon-txt.webp" class="img-fluid patreon" alt="patreon"> </a>
          </div>
          <p class="note-text">• NOTE : Patreon is for <strong>seamless</strong> monthly membership experience for
            <strong>exclusivity</strong> and support.
          </p>

          <div class="donate_links">
            <a [cdkCopyToClipboard]="gpay_id" (click)="openSnackBar()"> <img src="./assets/images/gpay.webp"
                class="img-fluid gpay" alt="gpay"> </a>
          </div>

          <p class="note-text">• NOTE : Tap on UPI icon to <strong>copy</strong> the UPI id. (For Indian Users)</p>

        </div>

      </div>
    </div>
  </div>

  <div class="container">
    <h1 style="text-align: left; font-weight: 600; color: #cf7b7d;"> Pay Attention! Beware of Scammers</h1>
    <p style="text-align: left; font-weight: 500;">- It has recently came to our knowledge that there has been a scammer
      named "ᴊᴀꜱᴡᴀʟ ᴀꜱʜɪꜱʜ" doing scams in the name of Elixir and some
      other roms for early access or exclusive builds. For more detailed info: <a target="_blank" class="key-high"
        href="https://www.buymeacoffee.com/uglykid/pay-attention-beware-scammers">Tap here</a> </p>
    <p style="text-align: left; font-weight: 500;">- We request our precious users to make sure that you don't get
      trapped
      in such scams which has been done by previous maintainers and other people. We only provide support on our
      official Patreon/Paypal/BMC links mention here only and not to any other specific maintainer's own
      Patreon/Paypal/BMC. </p>
    <p style="text-align: left; font-weight: 500;">- Beware of Scammers who wants your account access or private builds.
      Don't fall for mod/cracks etc and you may end up in trouble.</p>
  </div>

  <br>

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <!-- List of Supporters and Donor -->
  <div class="container website_animation" *ngIf="showFooter">

    <div>
      <h1 class="features"> Big Thanks to all the <strong class="key-high">Supporter / Sponsors</strong></h1>

    </div>

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">61</th>
          <td>Jhonathan Galeano</td>
          <td>Member from 1year</td>
        </tr>
        <tr>
          <th scope="row">60</th>
          <td>Sebastian Schuhmann</td>
          <td>Member from 1year</td>
        </tr>
        <tr>
          <th scope="row">59</th>
          <td>Igor Linhaus Sossai</td>
          <td>21st Dec '24</td>
        </tr>
        <tr>
          <th scope="row">58</th>
          <td>MemChoXD</td>
          <td>11th Dec '24</td>
        </tr>
        <tr>
          <th scope="row">57</th>
          <td>John Biddiscombe</td>
          <td>Dec '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">56</th>
          <td>Cis Elst</td>
          <td>Oct '24 [Fanatic]</td>
        </tr>
        <tr>
          <th scope="row">55</th>
          <td>Colobok Sadkolop</td>
          <td>Sep '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">54</th>
          <td>avdhzzz</td>
          <td>24th Aug '24</td>
        </tr>
        <tr>
          <th scope="row">53</th>
          <td>Guddu Choudhary</td>
          <td>Aug '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">52</th>
          <td>TJ Hobbs</td>
          <td>01st Jul '24</td>
        </tr>
        <tr>
          <th scope="row">51</th>
          <td>HenRy63</td>
          <td>May '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">50</th>
          <td>samsam1111</td>
          <td>May '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">49</th>
          <td>ken9k</td>
          <td>Mar '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">48</th>
          <td>Iskandar Masharipov</td>
          <td>02nd Mar '24</td>
        </tr>
        <tr>
          <th scope="row">47</th>
          <td>Jannik Neumann</td>
          <td>18th Feb '24</td>
        </tr>
        <tr>
          <th scope="row">46</th>
          <td>Masonn</td>
          <td>Jan '24 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">45</th>
          <td>King Razer</td>
          <td>13th Dec '23</td>
        </tr>
        <tr>
          <th scope="row">44</th>
          <td>Imre Veres</td>
          <td>04th Nov '23</td>
        </tr>
        <tr>
          <th scope="row">43</th>
          <td>Luke Duncan Farnsworth</td>
          <td>1st Nov '23</td>
        </tr>
        <tr>
          <th scope="row">42</th>
          <td>Jay Mandava</td>
          <td>1st Nov '23</td>
        </tr>
        <tr>
          <th scope="row">41</th>
          <td>Germán Sevilla</td>
          <td>20th Oct '23</td>
        </tr>
        <tr>
          <th scope="row">40</th>
          <td>Giannis Nikolaidis </td>
          <td>10th Oct '23</td>
        </tr>
        <tr>
          <th scope="row">39</th>
          <td>Jeyanth Mandava </td>
          <td>09th Oct '23</td>
        </tr>
        <tr>
          <th scope="row">38</th>
          <td>Maath Till </td>
          <td>Sep '23 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">37</th>
          <td>Rene de veth </td>
          <td>Sep '23 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">36</th>
          <td>Tomas M </td>
          <td>04th Aug '23</td>
        </tr>
        <tr>
          <th scope="row">35</th>
          <td>Joseph C McCoy </td>
          <td>02nd Jul '23</td>
        </tr>
        <tr>
          <th scope="row">34</th>
          <td>Emilia Moon</td>
          <td>01st Jun '23</td>
        </tr>
        <tr>
          <th scope="row">33</th>
          <td>Seth Fox</td>
          <td>04th May '23</td>
        </tr>
        <tr>
          <th scope="row">32</th>
          <td>Mohamed</td>
          <td>04th May '23</td>
        </tr>
        <tr>
          <th scope="row">31</th>
          <td>Mahmoud Nabeel</td>
          <td>01st May '23</td>
        </tr>
        <tr>
          <th scope="row">30</th>
          <td>HenRy63</td>
          <td>Apr '23 [Annually]</td>
        </tr>
        <tr>
          <th scope="row">29</th>
          <td>PowerlessMike </td>
          <td>18th Mar '23</td>
        </tr>
        <tr>
          <th scope="row">28</th>
          <td>iYas </td>
          <td>11th Mar '23</td>
        </tr>
        <tr>
          <th scope="row">27</th>
          <td>Austin Turner</td>
          <td>20th Feb '23</td>
        </tr>
        <tr>
          <th scope="row">26</th>
          <td>Dan King</td>
          <td>28th Jan '23 </td>
        </tr>
        <tr>
          <th scope="row">25</th>
          <td>Jordi Sobreques Soriano </td>
          <td>15th Jan '23</td>
        </tr>
        <tr>
          <th scope="row">24</th>
          <td>Henry Gläser </td>
          <td>11th Dec '22</td>
        </tr>

        <tr>
          <th scope="row">23</th>
          <td>Laurent Etie </td>
          <td>22nd Oct '22</td>
        </tr>

        <tr>
          <th scope="row">22</th>
          <td>Pablo </td>
          <td>14th Oct '22</td>
        </tr>
        <tr>
          <th scope="row">21</th>
          <td>Joel Roy </td>
          <td>02nd Oct '22 </td>
        </tr>
        <tr>
          <th scope="row">20</th>
          <td>Zinger </td>
          <td>30th Sep '22</td>
        </tr>

        <tr>
          <th scope="row">19</th>
          <td>Niwash Kumar </td>
          <td>26th Sep '22</td>
        </tr>
        <tr>
          <th scope="row">18</th>
          <td>Ali Amjad </td>
          <td>19th Sep '22</td>
        </tr>

        <tr>
          <th scope="row">17</th>
          <td>Nicola Di Rosso</td>
          <td>13th Sep '22</td>
        </tr>

        <tr>
          <th scope="row">16</th>
          <td>Mahmoud Mostafa</td>
          <td>11th Sep '22</td>
        </tr>
        <tr>
          <th scope="row">15</th>
          <td>Abiko </td>
          <td>15th Aug '22</td>
        </tr>
        <tr>
          <th scope="row">14</th>
          <td>Michael </td>
          <td>11th Jul '22</td>
        </tr>

        <tr>
          <th scope="row">13</th>
          <td>Boyd </td>
          <td>06th Jul '22</td>
        </tr>
        <tr>
          <th scope="row">12</th>
          <td>Mario Tagliaccica</td>
          <td>24th Jun '22</td>
        </tr>
        <tr>
          <th scope="row">11</th>
          <td>Jan Christian Mongkol</td>
          <td>20th Jun '22</td>
        </tr>

        <tr>
          <th scope="row">10</th>
          <td>Manu Vallejo</td>
          <td>12th Jun '22</td>
        </tr>
        <tr>
          <th scope="row">09</th>
          <td>Markus </td>
          <td>10th Jun '22 </td>
        </tr>
        <tr>
          <th scope="row">08</th>
          <td>Darth Jabba</td>
          <td>27th May '22</td>
        </tr>
        <tr>
          <th scope="row">07</th>
          <td>Benjamin Ambrose</td>
          <td>25th May '22</td>
        </tr>

        <tr>
          <th scope="row">06</th>
          <td>Jan Triska</td>
          <td>15th May '22</td>
        </tr>
        <tr>
          <th scope="row">05</th>
          <td>Antonio Barile</td>
          <td>19th Apr '22</td>
        </tr>
        <tr>
          <th scope="row">04</th>
          <td>Bernd Riehmer</td>
          <td>10th Mar '22</td>
        </tr>

        <tr>
          <th scope="row">03</th>
          <td>Domenico Pansera</td>
          <td>26th Feb '22</td>
        </tr>
        <tr>
          <th scope="row">02</th>
          <td>Leo Smid</td>
          <td>17th Feb '22</td>
        </tr>
        <tr>
          <th scope="row">01</th>
          <td>Victor Hugo Guerra Sandoval</td>
          <td>13th Feb '22</td>
        </tr>
      </tbody>
    </table>
    <br>
    <div>
      <h4 style="text-align: center;">Special Thanks to <a target="_blank" class="key-high"
          href="https://forum.xda-developers.com/m/theelderstatesman.6321840/#about">theelderstatesman</a> who had
        donated to me for the very first time.</h4>
    </div>

  </div>
</div>

<div *ngIf="showFooter" class="mb-5 bg-body " style="background-color: #ffe9e9; padding: 2rem;">

  <p style="text-align: left;"><strong class="key-high">NOTE:</strong> </p>
  <p style="text-align: left;">- This chart has been maintained from May '22. Before that info may or may not be
    available right now. </p>
  <p style="text-align: left;">- Don't get confused. Only those who have donated much enough are featured here. For
    more
    contact <a target="_blank" class="key-high" href="https://telegram.me/ugly_kid_af">here</a> </p>

</div>

<div *ngIf="showFooter">
  <app-footer></app-footer>
</div>